<template>
  <div class="container">
    <AppModalBalanceTypes id="modal-balance-types" />
    <div class="d-flex header">
      <div class="col-sm-12 col-md-7 col-lg-7">
        <WhiteLabel>
          <div
            class="details-balance"
            :class="{ blocked: getBalance.balanceBlocked }"
          >
            <div class="balance">
              <span class="mb-3">
                <span v-if="getBalance.balanceBlocked"> Saldo bloqueado </span>
                <span v-else> Saldo em conta {{ getAcountType }} </span>
                <a class="pointer ml-3" @click="showBalance = !showBalance">
                  <em class="icon-blocked icon-eye white" />
                </a>
              </span>
              <div class="inline mt-1 mb-3">
                <span class="text-details f-16">R$</span>
                <span
                  v-show="showBalance"
                  class="text-details"
                  :class="getBalance.currentBalance < 0 ? 'pl-2' : ''"
                >
                  {{
                    getBalance.currentBalance
                      | currency('&nbsp;', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span
                  v-show="!showBalance"
                  class="text-details"
                  :class="getBalance.currentBalance < 0 ? 'ml-1 pl-2' : 'ml-1 '"
                >
                  .....
                </span>
                <em
                  class="icon icon-blocked lock-icon"
                  v-if="getBalance.balanceBlocked"
                />
              </div>
              <span v-if="getBalance.balanceBlocked" class="info">
                Seu saldo será liberado quando todas as pendências forem
                resolvidas
              </span>
            </div>
            <div class="details-balance-subnivel">
              <router-link
                to="/proximos-lancamentos"
                class="text-white pointer"
              >
                <span v-show="showBalance" class="text-details f-18 block">
                  {{
                    getBalance.futureBalance
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span v-show="!showBalance" class="text-details f-18 block">
                  R$ .....
                </span>
                <span>A receber</span>
              </router-link>

              <!-- Trecho comentando pois a antecipação manual foi descontinuada indefinidamente -->
              <!-- <div v-if="getBalance.anticipatedBalance > 0">
                <span
                  class="text-details f-18 block"
                >{{ getBalance.anticipatedBalance | currency("R$ ", 2, { decimalSeparator: ',' , thousandsSeparator: '.' }) }}</span>
                <span>Antecipáveis</span>
              </div> -->

              <div v-if="getBalance.amountBlocked > 0">
                <span v-if="showBalance" class="text-details f-18 block">
                  {{
                    getBalance.amountBlocked
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span v-show="!showBalance" class="text-details f-18 block">
                  R$ .....
                </span>
                <span class="amountBlockedText">
                  Valor retido pela análise de risco
                  <em
                    class="icon help-icon white amountBlockedIcon"
                    data-toggle="tooltip"
                    data-html="true"
                    data-placement="bottom"
                    title="Este valor está retido para avaliação de risco de sua conta. Fique atento a sua caixa de e-mail que você receberá a solicitação da documentação necessária."
                  />
                </span>
              </div>

              <div v-if="getBalance.dueBalance > 0">
                <span v-show="showBalance" class="text-details f-18 block">
                  {{
                    getBalance.dueBalance
                      | currency('R$ -', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
                <span v-show="!showBalance" class="text-details f-18 block">
                  R$ .....
                </span>
                <span>Saldo Devedor</span>
              </div>
            </div>
            <div v-show="true" class="accountInformation p-3">
              <div>
                <p>
                  <span> Dados para receber </span>
                  <span
                    :class="
                      showPixAccountInfo ? 'text-details f-16' : 'info-account'
                    "
                  >
                    <a
                      class="pointer"
                      @click="
                        showPixAccountInfo = true;
                        showTedAccountInfo = false;
                      "
                    >
                      Pix
                    </a>
                  </span>
                  <span> | </span>
                  <span
                    :class="
                      showTedAccountInfo ? 'text-details f-16' : 'info-account'
                    "
                  >
                    <a
                      class="pointer"
                      @click="
                        showTedAccountInfo = true;
                        showPixAccountInfo = false;
                      "
                    >
                      Ted
                    </a>
                  </span>
                </p>
                <span v-if="showTedAccountInfo" class="info-account">
                  Banco:
                </span>
                <span v-if="showTedAccountInfo" class="mb-1 text-details f-16">
                  633
                </span>
                <span v-if="showPixAccountInfo" class="info-account">
                  ISPB:
                </span>
                <span v-if="showPixAccountInfo" class="mb-1 text-details f-16">
                  17819084
                </span>
                <span class="info-account"> Agência: </span>
                <span class="mb-1 text-details f-16">
                  {{ getBalance.accountBranch }}
                </span>
                <span class="info-account"> Conta: </span>
                <span class="mb-1 text-details f-16">
                  {{ getBalance.accountNumber }}
                </span>
              </div>
              <div class="align-self-end">
                <a
                  data-toggle="modal"
                  data-target="#modal-balance-types"
                  class="pointer"
                >
                  <em class="icon help-icon white" />
                </a>
              </div>
            </div>
          </div>
        </WhiteLabel>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-5">
        <ListOptions :balance="getBalance" />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import ListOptions from '@/components/dashboard/list-option/ListOption';
import AppModalBalanceTypes from '@/components/shared/modal-balance-types/ModalBalanceTypes';
// import AppModalPreferencesBase from '@/components/account/preferences/ModalPreferencesBase';
import WhiteLabel from '@/components/shared/WhiteLabel';
import { mapActions } from 'vuex';

export default {
  name: 'AppBalanceWidget',
  components: {
    ListOptions,
    AppModalBalanceTypes,
    // AppModalPreferencesBase,
    WhiteLabel,
  },

  props: {
    balance: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    settings: null,
    automaticDays: null,
    // modalPreferencesBase: 'modalPreferencesBase',
    bankAccount: null,
    pagcertoInstitution: '17819084 - Pagcerto Soluções em Pagamento S.A.',
    showBalance: false,
    showPixAccountInfo: true,
    showTedAccountInfo: false,
  }),

  computed: {
    getBalance() {
      return this.balance;
    },

    getAcountType() {
      switch (this.balance.accountType) {
        case 'checking':
          return 'corrente';

        case 'savings':
          return 'poupança';

        case 'salary':
          return 'salário';

        default:
          break;
      }
    },

    getSettingsFormatting() {
      let daysFormatting = '';

      if (this.settings) {
        if (this.settings.isMonday) daysFormatting += 'Seg, ';
        if (this.settings.isTuesday) daysFormatting += 'Ter, ';
        if (this.settings.isWednesday) daysFormatting += 'Qua, ';
        if (this.settings.isThursday) daysFormatting += 'Qui, ';
        if (this.settings.isFriday) daysFormatting += 'Sex, ';

        if (!daysFormatting) {
          daysFormatting = 'Desligado';
        } else {
          const last = daysFormatting.lastIndexOf(',');
          daysFormatting = daysFormatting.substring(last, '');
        }
      }

      return daysFormatting;
    },
  },

  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.getSettingsAccount();
    this.$bus.$emit('spinner-stop');

    await this.getPreferencesAccount();
    this.$bus.$on('closePreferences', (result) => {
      this.getPreferencesAccount();
    });

    await this.loadBankAccount();

    /* eslint-disable no-undef */
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },

  methods: {
    async getSettingsAccount() {
      const api = new PaymentAccountsApi();
      this.settings = await api.getSettings();
    },

    async getPreferencesAccount() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      this.settings = await api.getSettings();
      this.$bus.$emit('spinner-stop');
    },

    // openModal() {
    //   /* eslint-disable no-undef */
    //   $('#modalPreferencesBase').modal('show');
    // },

    ...mapActions({
      loadBankAccount: 'account/loadBankAccount',
    }),
  },
};
</script>

<style lang="scss" scoped>
.icon.info-icon {
  height: 1rem;
  margin-left: 1rem;
}

.amountBlockedIcon {
  display: inline-block;
  width: 1.5rem;
  height: 1rem;
}

.amountBlockedText {
  font-size: 0.8em;
}

.accountInformation {
  display: flex;
  justify-content: space-between;
  margin-left: 1.8rem;
}

.noTextTransform {
  text-transform: none;
}
</style>
