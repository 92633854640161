<template>
  <div>
    <div class="container">
      <div class="row">
        <app-balance-widget
          :balance="balance"
          v-if="!isNavigation('partner')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppBalanceWidget from '@/components/dashboard/balance-widget/BalanceWidget';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AccountApi from '@/services/AccountApi';

export default {
  name: 'AppDashboard',
  components: {
    AppBalanceWidget,
  },
  data() {
    return {
      balance: {},
      settings: null,
      seller: window.localStorage.getItem('dashboardSeller') || '',
    };
  },
  async mounted() {
    if (this.isNavigation('partner')) {
      this.$router.push({ name: 'charts' });
    }
    await this.getBalanceAccount();

    this.$bus.$on('update', (result) => {
      this.getBalanceAccount();
    });
  },
  methods: {
    async getBalanceAccount() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      this.balance = await api.getBalance();
      this.$bus.$emit('spinner-stop');
    },
    isNavigation(criteria) {
      return window.localStorage.getItem('navigation') === criteria;
    },
  },
};
</script>

<style scoped>
.btn__remove-filter {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}
</style>
